const languages = [`es`, `en`, `de`]
const siteId = 2
const experiences = [705, 735]

module.exports = {
  siteMetadata: {
    title: "siteTitle",
    description: "siteDescription",
    siteUrl: "https://caminobarrancodemasca.dev.volcanoteide.com",
    siteId: siteId,
    menuLinks: [
      {
        name: "Inicio",
        path: "/",
        icon: "icon-mini-casa",
      },
      {
        name: "Masca",
        icon: "icon-mini-palmera",
        section: 19,
      },
      {
        name: "Reservar",
        experience: 705,
        icon: "icon-mini-ticket",
      },
      {
        name: "Contacto",
        article: 132,
        icon: "icon-mini-sobre",
      },
      {
        name: "Masca hoy",
        article: 133,
        icon: "icon-mini-sol",
      },
    ],
    footer: [
      {
        name: "Páginas legales",
        styleClass: "col-md-3",
        articles: [
          {
            name: "Términos y condiciones",
            article: 128,
          },
          {
            name: "Privacidad",
            article: 143,
          },
          {
            name: "Cookies",
            article: 144,
          },
          {
            name: "Aviso legal",
            article: 145,
          },
        ],
      },
      {
        name: "Explora el camino de masca",
        styleClass: "col-md-4",
        articles: [
          {
            name: 'Gestiona tu reserva',
            article: 134
          },
          {
            name: "Autorización a menores",
            resource: "Autorizacion_menores_Esp.pdf",
            dest: "blank",
          },
          {
            name: "Medidas extraordinarias debido a la COVID-19",
            article: 146,
            dest: "blank",
          },
        ],
      },
      {
        name: "Empresas colaboradoras",
        styleClass: "col-md-5",
        articles: [
          {
            name:
              "Empresas colaboradoras de Turismo Activo y Viviendas Vacacionales",
            article: 137,
          },
          {
            name: "Para ser empresa de Turismo Activo colaboradora",
            article: 139,
          },
          {
            name: "Empresas de transporte",
            article: 166,
          },
        ],
      },
    ],
    resources: {
      i18n: [
        "Autorización_menores_Esp.pdf",
        "INFO-TENO-ESP.pdf",
        "1-PARQUE-RURAL-DE-TENO-ES.wav",
        "2-CASERIO-DE-MASCA-ESP.wav",
        "3-BARRANCO-DE-MASCA-ES.wav",
      ],
    },
    languages: languages,
    experiences: experiences,
    cookiesPolicyArticle: 144,
    termsAndConditionsArticle: 128,
    noIndexArticles: [203, 204, 205],
    googleAPIKey: `AIzaSyDdKVpsd7bYd_Wdk6DCtqjaaXH2-3eIYYQ`,
    googleReCaptchaAPIKey: "6Lf759MZAAAAADWgbF4KReNUChvQTCmsuz5vhkr_",
    environment: process.env.ENVIRONMENT,
    gtmTrackingId: process.env.GTM_TRACKING_ID,
    gaTrackingId: process.env.GA_TRACKING_ID,
    volcanoTeideAPIConfig: {
      protocol: "https",
      host: process.env.API_URL,
      port: 443,
      strictSSL: true,
      timeout: 10000,
      site_key: "caminomasca",
    },
    contactFormURL:
      "https://63fl5qoyx5.execute-api.eu-west-1.amazonaws.com/pre/contacto",
    appAPIKey: "caminomasca",
    statusAPIURL: "https://api.qa.stg.volcanoteide.com/widgets/status",
    roadStatusURL: "http://cic.tenerife.es/e-Traffic3/Control?act=detMapInci",
    tide1StatusURL:
      "http://www.aemet.es/es/eltiempo/prediccion/playas/la-arena-3804004",
    tide2StatusURL:
      "http://www.aemet.es/es/eltiempo/prediccion/playas/la-ballenita-3801005",
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-plugin-sass`,
      options: {
        precision: 6,
      },
    },
    {
      resolve: `volcanoteide-api-plugin`,
      options: {
        baseURL: `https://` + process.env.API_URL,
        languages: languages,
        apiKey: `caminomasca`,
        siteId: siteId,
        experiences: experiences,
        resources: [
          {
            name: "Autorizacion_menores_Esp.pdf",
            i18n: {
              es: "Autorizacion_menores_Esp.pdf",
              en: "Autorizacion_menores_ingles.pdf",
              de: "Autorizacion_menores_Aleman.pdf",
            },
          },
          {
            name: "INFO-TENO-ESP.pdf",
            i18n: {
              es: "INFO-TENO-ESP.pdf",
              en: "INFO-TENO-ENG.pdf",
              de: "INFO-TENO-DE.pdf",
            },
          },
          {
            name: "1-PARQUE-RURAL-DE-TENO-ES.wav",
            i18n: {
              es: "1-PARQUE-RURAL-DE-TENO-ES.wav",
              en: "1-PARQUE-RURAL-DE-TENO-EN.wav",
              de: "1-PARQUE-RURAL-DE-TENO-DE.wav",
            },
          },
          {
            name: "2-CASERIO-DE-MASCA-ESP.wav",
            i18n: {
              es: "2-CASERIO-DE-MASCA-ESP.wav",
              en: "2-CASERIO-DE-MASCA-EN.wav",
              de: "2-CASERIO-DE-MASCA-DE.wav",
            },
          },
          {
            name: "3-BARRANCO-DE-MASCA-ES.wav",
            i18n: {
              es: "3-BARRANCO-DE-MASCA-ES.wav",
              en: "3-BARRANCO-DE-MASCA-EN.wav",
              de: "3-BARRANCO-DE-MASCA-DE.wav",
            },
          },
        ],
      },
    },
    `gatsby-plugin-sharp`,
    `gatsby-transformer-sharp`,
    {
      resolve: `gatsby-plugin-s3`,
      options: {
        bucketName: process.env.S3_BUCKET_NAME,
        generateRoutingRules: false,
        generateRedirectObjectsForPermanentRedirects: false,
      },
    },
    {
      resolve: `gatsby-plugin-react-i18next`,
      options: {
        path: `${__dirname}/src/locales`,
        languages: languages,
        defaultLanguage: ``,

        // you can pass any i18next options
        // pass following options to allow message content as a key
        i18nextOptions: {
          interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
          },
          keySeparator: false,
          nsSeparator: ":",
        },
        pages: [
          {
            matchPath: "/",
            getLanguageFromPath: false,
          },
          {
            matchPath: "/booking",
            getLanguageFromPath: false,
          },
          {
            matchPath: "/checkout",
            getLanguageFromPath: false,
          },
          {
            matchPath: "/checkout/result",
            getLanguageFromPath: false,
          },
          {
            matchPath: "/checkout/payment",
            getLanguageFromPath: false,
          },
          {
            matchPath: "/404",
            getLanguageFromPath: false,
          },
          {
            matchPath: "/:lang/(.*)",
            getLanguageFromPath: true,
          },
        ],
      },
    },
  ],
}
