import React from "react"
import Layout from '../components/Layout'
import Sections from '../components/Sections'
import Helmet from "react-helmet"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { graphql } from "gatsby"
import { siteMetadata } from "../../gatsby-config";
import { useLocation } from "@reach/router"
import favicon from "../../static/favicon.svg"

export const query = graphql`
  query IndexImage {
    allFile(
      filter: {
        url: {
          eq: "https://api.qa.stg.volcanoteide.com/img/cache/1151x468_621145465_mirador-barranco-masca-pareja.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          fixed(width: 1110, height: 400, fit: COVER, cropFocus: CENTER) {
            src
          }
        }
      }
    }
  }
`

export default function Home({ data }) {
  const { t } = useTranslation('Index');
  const { title, description } = useSiteMetadata();
  const location = useLocation();
  return (
    <Layout>
      <Helmet>
        <title>{t(title)}</title>
        <link rel="icon" href={favicon} />
        <meta name="title" content={t(title)} />
        <meta name="og:title" content={t(title)} />
        <meta name="og:site_name" content={title} />
        <meta name="description" content={t(description)} />
        <meta name="og:type" content="website" />
        <meta name="og:url" content={location.href} />
        <meta name="og:description" content={t(description)} />
        {siteMetadata.languages.map((language, key) =>
          <link rel="alternate" key={key} hrefLang={`${language}`} href={`${siteMetadata.siteUrl}/${language}/`} />)
        }
        <link rel="canonical" href={location.href} />
      </Helmet>
      <div className="espacio-blanco"></div>
      <div className="imagen-superior" style={data.allFile.nodes[0] && { backgroundImage: `url(${data.allFile.nodes[0].childImageSharp.fixed.src})` }}>
        <div className="image-primary">{t("Te damos la BIENVENIDA")}&nbsp;<span>{t("al camino del barranco de Masca")}</span><span>Buenavista del Norte</span></div>
        <div className="image-secondary">{t("Tu seguridad y la conservación del barranco,")}&nbsp;<span>{t("nuestras prioridades")}</span></div>
      </div>
      <article className={"articulo index text-justify"}>
        <Sections refs={[122, 705, 132, 133]} />
      </article>
    </Layout>
  )
}
